import React from 'react';
import {
  CategoriesHeader,
  LoyaltyBanner,
  useOffersAndRewardsMenu,
} from '@fingermarkglobal/cringer.components';
import { GuestTitleComponent } from './GuestTitleComponent';
import { MemberTitleComponent } from './MemberTitleComponent';
import { OffersAndRewards } from '../offers-and-rewards';
import OffersImage from '../../../assets/images/OffersImage_600x600.png';
import RewardsImage from '../../../assets/images/RewardsImage_ver2_600x600.png';

const Header = ({
  className = '',
  showCategoriesHeader = false,
  isHeaderTitleEnabled = false,
  title = '',
  showLoyaltyBanner = false,
  computedAction = () => {},
} = {}) => {
  const { offersAndRewards } = useOffersAndRewardsMenu();

  const hasOffers = offersAndRewards?.offers?.length > 0;
  const hasRewards = offersAndRewards?.rewards?.length > 0;

  const offersCategoryItem = hasOffers
    ? {
        id: 'offers',
        imageUrl: OffersImage,
        title: 'Offers & Deals',
        path: '/category/offers',
      }
    : null;

  const rewardsCategoryItem = hasRewards
    ? {
        id: 'rewards',
        imageUrl: RewardsImage,
        title: 'Rewards',
        path: '/category/rewards',
      }
    : null;

  const categoriesWithOffersAndRewards = [offersCategoryItem, rewardsCategoryItem];

  return (
    <div data-test="header" className={`flex flex-col ${className}`}>
      {showLoyaltyBanner && (
        <LoyaltyBanner
          computedAction={computedAction}
          MemberTitleComponent={MemberTitleComponent}
          GuestTitleComponent={GuestTitleComponent}
        />
      )}
      {showCategoriesHeader && (
        <CategoriesHeader
          offersAndRewardsCategories={categoriesWithOffersAndRewards}
          isIconMinimal
          isItemRounded={false}
        />
      )}

      <OffersAndRewards />

      {title && isHeaderTitleEnabled && (
        <div className={`flex text-4xl px-8 py-8 pt-12 font-header items-center`}>
          <div className="flex items-center">{title}</div>
        </div>
      )}
    </div>
  );
};

export { Header };
